<template>
  <h3>{{ attendees.length }} players in {{ numTeams }} teams</h3>
  <div class="flex one two-600" style="flex-grow: 1">
    <div v-for="(team, i) in teams" :key="i">
      <TeamCard :team="team" :num-teams="teams.length"/>
    </div>
  </div>
</template>

<script>
import TeamCard from "@/components/TeamCard.vue";

import {fillTeams, Team} from "team-maker";
import {computed} from "vue";

export class NamedTeam extends Team {
  name
}

const initializeTeams = (numTeams, allPlayers) => {
  const tmpSet = new Set()
  allPlayers.forEach(a => {
    if (!a.team) return
    tmpSet.add(a.team)
  })
  const teamNames = []
  tmpSet.forEach(e => {
    teamNames.push(e)
  })

  const teams = []
  let remainingPlayers = allPlayers
  for (let i = 0; i < Math.max(numTeams, tmpSet.size); i++) {
    const t = new NamedTeam()
    const name = teamNames[i]
    if (name === undefined) {
      t.name = `Team ${i + 1}`
      teams.push(t)
      continue
    }

    t.name = teamNames[i]
    remainingPlayers = remainingPlayers.filter(a => {
      if (a.team === t.name) {
        t.addPlayer(a.positions[0] || "", a)
      }
      return a.team !== t.name
    })

    teams.push(t)
  }

  return [teams, remainingPlayers]
}
const generateTeams = (numTeams, allPlayers, definition) => {
  const [teams, remainingPlayers] = initializeTeams(numTeams, allPlayers)
  fillTeams(teams, remainingPlayers, definition)
  return teams
}

export default {
  name: "StaffPanel",
  components: {TeamCard},
  props: {attendees: Array, numTeams: Number, definition: Array},
  setup(props) {
    const teams = computed(() => generateTeams(props.numTeams, props.attendees, props.definition))
    return {teams}
  }
}
</script>
