<template>
  <main class="container center">
    <header><h1>Shula's team-maker engine</h1></header>
    <HelpTips/>
    <div class="flex one two-600">
      <fieldset class="off-none">
        <legend><h4>Player list</h4></legend>
        <textarea id="players-text" :class="error ? 'error' : ''"
                  v-model="inputText"
                  placeholder="Jhon Wick, F, 701"
                  rows="7"
                  @blur="loadPlayers"/>
      </fieldset>
      <div class="flex">
        <div class="full half-700">
          <h4>Number of teams: {{ numTeams }}</h4>
          <input type="range" min="2" max="10" v-model.number="numTeams"/>
          <h4>Team definition</h4>
          <input type="text" placeholder="FB:1, C:1, W:2, F:2" @change="teamDefinitionChanged"/>
          <span class="error" v-if="teamDefinitionErr">check positions format</span>
        </div>
      </div>
    </div>
    <StaffPanel v-if="!teamDefinitionErr" :attendees="players" :num-teams="numTeams" :definition="teamDefinition"/>
  </main>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {decode, encode} from "js-base64"
import HelpTips from "@/components/HelpTips.vue";
import StaffPanel from "@/components/StaffPanel.vue";

const demo = [
  {name: "Iron man", positions: ['F', 'W'], score: 80, team: undefined},
  {name: "Captain America", positions: ['FB', 'C'], score: 76, team: undefined},
  {name: "Thor", positions: ['C', 'W'], score: 90, team: undefined},
  {name: "Hawkeye", positions: ['FB', 'W'], score: 70, team: undefined},
  {name: "Hulk", positions: ['F', 'C'], score: 90, team: undefined},
  {name: "Falcon", positions: ['W', 'FB'], score: 60, team: undefined},
  {name: "War machine", positions: ['F'], score: 50, team: undefined},
  {name: "Scarlet witch", positions: ['FB'], score: 80, team: undefined},
  {name: "Wasp", positions: ['C', 'FB'], score: 65, team: undefined},
  {name: "Spider-man", positions: ['F', 'W'], score: 80, team: undefined},
  {name: "Star-lord", positions: ['FB', 'C'], score: 63, team: undefined},
  {name: "Nebula", positions: ['W', 'F'], score: 30, team: undefined},
  {name: "Gamora", positions: ['F', 'W'], score: 60, team: undefined},
  {name: "Drax", positions: ['FB', 'C'], score: 30, team: undefined},
  {name: "Rocket", positions: ['W'], score: 40, team: undefined},
  {name: "Groot", positions: ['F', 'W'], score: 70, team: undefined},
  {name: "Mantis", positions: ['C', 'F'], score: 55, team: undefined},
  {name: "Dr Strange", positions: ['W', 'FB'], score: 80, team: undefined},
  {name: "Wong", positions: ['F', 'C'], score: 50, team: undefined},
  {name: "Black panther", positions: ['C', 'W'], score: 70, team: undefined},
  {name: "Okoye", positions: ['FB', 'F'], score: 40, team: undefined},
  {name: "Bucky", positions: ['FB', 'F'], score: 50, team: undefined},
  {name: "Valkyrie", positions: ['F', 'W'], score: 60, team: undefined},
  {name: "Captain marvel", positions: ['W', 'C'], score: 90, team: undefined},
  {name: "Shuri", positions: ['FB', 'C'], score: 30, team: undefined},
  {name: "M'Baku", positions: ['F', 'W'], score: 30, team: undefined},
  {name: "Pepper pots", positions: ['F', 'C'], score: 60, team: undefined},
  {name: "Korg", positions: ['W', 'FB'], score: 20, team: undefined},
]

const separator = ","
const demoText = () => {
  return demo.reduce((result, {name, positions, score, team}) => {
    positions = positions.join(";")
    return result + [name, positions, score, team].join(separator) + "\n"
  }, "")
}

const parseLine = line => {
  const [name, positions, score, team] = line.split(separator)
  return {name, positions: positions.split(";"), score: parseInt(score), team}
}

const parsePositions = str => {
  if (str === "" || str === undefined) {
    return []
  }
  return str.split(",").map(e => {
    const [pos, num] = e.trim().split(":")
    return {pos, num}
  })
}

export default {
  name: 'App',
  components: {StaffPanel, HelpTips},
  setup() {
    let inputText = ref(demoText())
    let error = ref(false)
    let players = ref(demo)
    let numTeams = ref(2)
    let teamDefinitionStr = ref("")
    let teamDefinitionErr = ref(false)
    const teamDefinition = computed(() => parsePositions(teamDefinitionStr.value))

    const teamDefinitionChanged = event => {
      const regex = /^([a-z]+:[1-9][0-9]*)*(,[a-z]+:[1-9][0-9]*)*$/gi;

      teamDefinitionErr.value = !regex.test(event.target.value)
      if (teamDefinitionErr.value) {
        return
      }
      teamDefinitionStr.value = event.target.value
    }

    const loadPlayers = () => {
      const loadedPlayers = []
      let lines = inputText.value.trim().split("\n")
      try {
        error.value = false
        for (const line of lines) {
          if (line.at(0) === "#") {
            continue
          }
          loadedPlayers.push(parseLine(line))
        }
        players.value = loadedPlayers
        // document.getElementById("staff-panel").scrollIntoView({
        //   behavior: "smooth"
        // });
      } catch {
        error.value = true
      }
    }
    watch(inputText, () => {
      window.location.hash = encode(inputText.value, true)
    })

    onMounted(() => {
      let hash = window.location.hash
      if (hash) {
        hash = hash.replace("#", "").replace("/", "")
        inputText.value = decode(hash)
        loadPlayers()
      }
    })

    return {
      loadPlayers,
      teamDefinitionChanged,
      inputText,
      teamDefinitionErr,
      teamDefinition,
      players,
      error,
      numTeams
    }
  }
}
</script>

<style>

main.container {
  padding: 0.8em;
  max-width: 960px;
}

.center {
  margin: 0 auto;
}
</style>
