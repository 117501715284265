<template>
  <article class="card">
    <header>
      <h4>{{ team.name }}</h4>
    </header>
    <div :set="num=1">
      <div v-for="(pos,i) in team.players().keys()" :key="i">
        <div v-for="(player,j) in team.players().get(pos)" :key="j">
          <span class="label success">{{ num++ }}</span>
          <span :class="`label ${player.positions.includes(pos)?'':'warning'}`">{{ pos }}</span>
          {{ player.name }}
          <i v-if="player.team" data-tooltip="Pre-assigned to a team"> 📌 </i>
        </div>
      </div>
    </div>
    <footer class="team-stats">
      <span>{{ team.avgScore().toFixed(2) }}</span>
      <span v-for="pos in team.players().keys()" :key="pos">
        <strong>{{ pos }}</strong>:
        {{ team.players().get(pos).length }}
      </span>
    </footer>
  </article>
</template>

<script>
import NamedTeam from "@/components/StaffPanel.vue";

export default {
  name: "TeamCard",
  props: {team: NamedTeam},
}
</script>

<style scoped>

article {
  margin: 5px;
}

article header {
  margin: 0 0 .5em 0;
}

article footer {
  margin: .5em 0 0 0;
}

.team-stats {
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid #aaa;
}
</style>