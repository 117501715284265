<template>
  <h3>How to use it?</h3>
  <p>
    Copy and paste the list of players you want to split into teams. Each line MUST contain the name, gender, position
    and score separated by comma (,) in the following formats: <code>&lt;name&gt;,&lt;position&gt;,&lt;score&gt;</code>
    or <code>&lt;name&gt;,&lt;position&gt;,&lt;score&gt;,&lt;team name&gt;</code>
  </p>
  <div class="flex five">
    <div class="full four-fifth-700">
      <p>
        <strong>i.e:</strong>
        <code>Bruce Wayne, A, 80</code> or <code>Harley Quinn, B, 50, Suicide squad</code>
      </p>
    </div>
    <div class="full one fifth-700">
      <label for="help-modal" class="button"> Need help? </label>
    </div>
  </div>
  <div class="modal">
    <input id="help-modal" type="checkbox"/>
    <label for="help-modal" class="overlay"></label>
    <article>
      <header>
        <h3>Follow these few tips</h3>
        <label for="help-modal" class="close">&times;</label>
      </header>
      <section class="content">
        <ul>
          <li>If a line starts with <code>#</code>, that line will be ignored.</li>
          <li><i>Position</i>: anything you need but it's recommended to use letters like <code>C, F, G, L, R, S,
            W</code>
          </li>
          <li><i>Score</i>: Positive non-decimal numbers like this range <code>1 - 100</code></li>
        </ul>
      </section>
    </article>
  </div>
</template>
<script setup>
</script>